<template>
  <div>
    <!-- Table Container Card -->
    <package-list-add-new
      :key="activePackage.fullname"
      :is-add-new-package-sidebar-active.sync="isAddNewPackageSidebarActive"
      :package="activePackage"
      @refetch-data="refetchData"
    />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end" />
          </b-col>
        </b-row>
      </div>
      <h4 class="ml-1">{{ $t("packagesPurchased") }}</h4>
      <b-table
        v-if="packagesPurchased.length > 0"
        ref="refPackageListTable"
        class="position-relative"
        :items="packagesPurchased[0].packagesPurchased"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noRecords')"
        :tbody-tr-class="rowClass"
      >
        <template #cell(expired)="data">
          <small class="align-middle ml-50 text-dark">
            <feather-icon v-if="!data.item.expired" icon="CheckIcon" />
            <feather-icon
              v-if="data.item.expired"
              size="20"
              icon="XIcon"
              color="red"
            />
          </small>
        </template>
        <template #cell(detail)="data">
          <b-link @click="selectPackage(data)">
            <feather-icon icon="FileTextIcon" />
            <small class="d-none d-lg-inline align-middle ml-50 text-dark">
              {{ $t("edit") }}
            </small>
          </b-link>
        </template>
        <!-- Column: Package -->
      </b-table>
      <h4 class="ml-1">{{ $t("otherPurchases") }}</h4>

      <b-table
        v-if="packagesPurchased.length > 0"
        ref="refPackageListTable"
        class="position-relative"
        :items="packagesPurchased[0].otherPurchases"
        responsive
        :fields="tableOtherColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('noRecords')"
      >
        <template #cell(expired)="data">
          <small class="align-middle ml-50 text-dark">
            <feather-icon v-if="!data.item.expired" icon="CheckIcon" />
            <feather-icon
              v-if="data.item.expired"
              size="20"
              icon="XIcon"
              color="red"
            />
          </small>
        </template>
        <template #cell(detail)="data">
          <b-link @click="selectPackage(data)">
            <feather-icon icon="FileTextIcon" />
            <small class="d-none d-lg-inline align-middle ml-50 text-dark">
              {{ $t("edit") }}
            </small>
          </b-link>
        </template>
        <!-- Column: Package -->
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BTable, BLink } from "bootstrap-vue";
import { ref, onMounted } from "@vue/composition-api";
import usePackageList from "../usePackageList";
import PackageListAddNew from "./PackageListAdd.vue";
export default {
  components: {
    PackageListAddNew,
    BCard,
    BTable,
    BLink,
    BRow,
    BCol,
  },

  setup() {
    const isAddNewPackageSidebarActive = ref(false);
    const activePackage = ref({});
    const {
      fetchPackages,
      tableColumns,
      tableOtherColumns,
      refetchData,
      refPackageListTable,

      packagesPurchased,
    } = usePackageList();

    const rowClass = (item, type) => {
      if (item && type === "row") {
        if (item.expired == 0) {
          return "";
        } else {
          return "bg-gray-100";
        }
      } else {
        return null;
      }
    };
    const selectPackage = (data) => {
      activePackage.value = data.item;
      isAddNewPackageSidebarActive.value = true;
    };
    onMounted(() => {
      fetchPackages();
    });
    return {
      // Sidebar
      isAddNewPackageSidebarActive,
      selectPackage,
      fetchPackages,
      activePackage,
      tableColumns,
      refPackageListTable,
      refetchData,
      packagesPurchased,
      tableOtherColumns,
      rowClass,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.bg-gray-100 {
  background-color: #f5f5f5;
}
</style>
