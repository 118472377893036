<template>
  <div><package-list action-tpye="dsada" /></div>
</template>

<script>
import PackageList from "./PackageList/PackageList.vue";
export default {
  components: {
    PackageList,
  },
  mounted() {},
};
</script>

<style></style>
