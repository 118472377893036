import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";
import tr from "dayjs/locale/tr";
import az from "dayjs/locale/az";
import ru from "dayjs/locale/az";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import dayjs from "dayjs";

export default function usePackageList() {
  // Use toast
  const toast = useToast();

  const refPackageListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: "PackageName",
      label: VueI18n.t("package") + " " + VueI18n.t("name"),
      formatter: title,
      sortable: false,
    },
    // { label: VueI18n.t("price"), key: "pricepaid", sortable: false },
    {
      key: "purchasedate",
      label: VueI18n.t("purchaseDate"),
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY")}`,
    },
    {
      key: "expiredate",
      label: VueI18n.t("expireDate"),
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY")}`,
    },
    { key: "formulas", label: VueI18n.t("formula"), sortable: false },
    { key: "plants", label: VueI18n.t("plants"), sortable: false },
    { key: "users", label: VueI18n.t("users"), sortable: false },
    { key: "expired", label: VueI18n.t("expired") },
  ];
  const tableOtherColumns = [
    {
      key: "tipname",
      label: VueI18n.t("tip"),
      sortable: false,
    },
    {
      key: "purchasedate",
      label: VueI18n.t("purchaseDate"),
      formatter: (val) =>
        `${dayjs(val).locale(`${VueI18n.locale}`).format("DD MMMM YYYY")}`,
      sortable: false,
    },
    { key: "adet", label: VueI18n.t("amount"), sortable: false },
  ];
  const perPage = ref(10);
  const totalPackages = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const typeFilter = ref(null);
  const genderFilter = ref(null);
  const statusFilter = ref(null);
  const packagesPurchased = ref([]);

  const dataMeta = computed(() => {
    const localItemsCount = refPackageListTable.value
      ? refPackageListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPackages.value,
    };
  });

  const refetchData = () => {
    refPackageListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, typeFilter, genderFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchPackages = async (ctx, callback) => {
    packagesPurchased.value = await store.dispatch(
      "modulesModule/fetchPackages"
    );
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchPackages,
    tableColumns,
    refPackageListTable,
    refetchData,
    packagesPurchased,
    tableOtherColumns,
    // Extra Filters
  };
}
